<template>
  <v-container>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          readonly
          required
          :rules="validateDate"
          outlined
          :value="dates"
          label="Período de validade"
          hint="DD/MM/YYYY Formato"
          persistent-hint
          append-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          id="validate"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title range></v-date-picker>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dates: "",
    menu1: false,
    date: [],
    validateDate: [
      (v) => (v || "").length > 0 || "Período de validade é obrigatório",
    ],
  }),

  watch: {
    date(value) {
      if (value.length == 2) {
        const start = new Date(value[0]+'T03:00:00.000Z');
        const end = new Date(value[1]+'T03:00:00.000Z');
        const dates = [start, end]
          .sort((a, b) => a - b)
          .map((date) => date.toJSON());
        this.$store.state.ads.editedAds.startDate = dates[0];
        this.$store.state.ads.editedAds.endDate = dates[1];
        this.dates = dates
          .map((date) => new Date(date).toLocaleDateString())
          .join(" ~ ");
      }
    },
  },

  mounted() {
    this.date = [
      this.$store.state.ads.editedAds.startDate.split("/").reverse().join("-"),
      this.$store.state.ads.editedAds.endDate.split("/").reverse().join("-"),
    ];
  },
};
</script>