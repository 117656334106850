<template>
  <div v-if="changed">
    <v-row class="pl-2 pr-2">
      <v-col md="6"><form-ads /></v-col>
      <v-col md="6"><preview-ads></preview-ads></v-col>
    </v-row>
  </div>
</template>

<script>
import { getAdsById } from "../../../services/ads";
import PreviewAds from "../preview/preview";
import FormAds from "./form";
export default {
  components: { FormAds, PreviewAds },
  data: () => ({
    changed: false,
  }),
  async mounted() {
    
    if (!this.$store.state.ads.editedAds._id) {
      getAdsById(this.$route.params.id)
        .then(() => (this.changed = true))
        .catch((err) =>
          this.$store.commit("failed", "Falha ao tentar editar: ", +err)
        );
    } else {
      this.changed = true;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>